.leaflet-pm-actions-container {
  right: 100%;
  z-index: 7 !important;
}

.leaflet-bar a {
  border: 1px solid #eee;
  border-width: 0 1px 0 1px;
  padding: 4px !important;
  width: 37px !important;
  height: 37px !important;
  display: flex;
  align-items: center;
}

.leaflet-pm-toolbar .control-icon {
  width: 22px !important;
  height: 22px !important;
}

[title='Edit Layers'] {
  border-left-width: 2px !important;
}

.leaflet-pm-toolbar {
  /* position: absolute; */
  z-index: 1000 !important;
  display: flex;
  border: 1px solid #eee !important;
}
.leaflet-pm-toolbar.leaflet-pm-draw {
  right: 170px;
}
.leaflet-pm-toolbar.leaflet-pm-edit {
  right: 0;
}

.my-custom-cluster {
  background-color: #0fa3b1; /* Tomato color */
  border: 3px solid #fff;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: x-large;
  font-weight: 700;
  line-height: 50px; /* Same as icon height for vertical alignment */
}
