@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}
.p-chips > ul {
  width: 100%;
}

.planning-items-container .p-carousel-items-container {
  transform: none !important;
}
.p-dialog-mask {
  z-index: 10 !important;
}

.focus-no-border:enabled:focus {
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
}

.no-resize {
  resize: none;
}


input.p-component, textarea.p-component , .p-component.p-multiselect , .p-component.p-inputwrapper {
  border-color: #f3f6f9;
  background-color: #f3f6f9;
  min-height: 55px;
}

input.p-component:focus, textarea.p-component:focus , .p-component.p-multiselect:focus, .p-component.p-inputwrapper:focus {
 box-shadow: none;
 border: #ebedf3;
 background-color: #ebedf3;
}

.p-input-icon-left > .p-inputtext {
 padding-left: 2.5rem;
}

.p-input-icon-right > .p-inputtext {
 padding-right: 2.5rem;
}

.p-input-icon-left > i:first-of-type {
 left: 0.75rem;
}
.p-input-icon-right > i:first-of-type {
 right: 0.75rem;
}


.input.p-component, textarea.p-component, .p-component.p-multiselect, .p-component.p-inputwrapper {
  min-height: 0 !important;
}