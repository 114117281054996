.fc-h-event {
  border: 0;
}
.fc-scroller {
  scrollbar-width: thin;
  scrollbar-color: #67529d #f2f2f2;
}

.fc-scroller::-webkit-scrollbar {
  height: 20px;
  background-color: #f2f2f2;
}

.fc-scroller::-webkit-scrollbar-thumb {
  background-color: #67529d;
  border-radius: 4px; /* Round the edges of the scrollbar thumb */
}

/* No hover styling, so the thumb color remains constant */
.fc-scroller::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Track color */
}
