.custom-fa-icon {
  background: none !important; /* Remove the background from the container div */
  border: none !important; /* No border for the container div */
  display: inline-flex; /* Ensure the div wraps the icon tightly */
  align-items: center; /* Center the icon inside the div */
  justify-content: center;
}

.custom-fa-icon i {
  color: rgb(0, 193, 39);
  font-size: 26px;
  border: 2px solid rgb(0, 193, 39);
  border-radius: 50%;
  padding: 10px;
  background: white;
}

.custom-fa-icon-exit {
  background: none !important; /* Remove the background from the container div */
  border: none !important; /* No border for the container div */
  display: inline-flex; /* Ensure the div wraps the icon tightly */
  align-items: center; /* Center the icon inside the div */
  justify-content: center;
}

.custom-fa-icon-exit i {
  color: red;
  font-size: 26px;
  border: 2px solid red;
  border-radius: 50%;
  padding: 10px;
  background: white;
}
