.mobile-data-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .mobile-data-table h3 {
    background-color: #f2f2f2;
    color: #333;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  
  .p-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .p-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .mobile-data-table-row {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding: 15px;
  }
  
  .mobile-data-table-label {
    font-weight: bold;
    color: #333;
  }
  
  .mobile-data-table-info {
    color: #666;
    margin-top: 5px;
  }
  
  /* Alternate row color */
  .mobile-data-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  